<template>
	<div id="app">
		<router-view />
		<vue-confirm-dialog></vue-confirm-dialog>
		<loader :visible="$root.loading"></loader>
	</div>
</template>

<script>
import { Trans } from '@/plugins/Translation';
import Loader from './components/Loader';
export default {
	components: { Loader },
	created() {
		Trans.changeLanguage(this.$store.getters.language);
	},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

